import React, { useEffect, useState } from 'react';
import AdminHeader from '../../../Templates/AdminHeader';
import AppHelmet from '../../../Components/AppHelmet';
import DispatchTwoList from '../Components/DispatchTwoList';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getCNRDetails } from '../Methods';
import Moment from 'react-moment';
import { useQuery } from '../../../Utility/Hooks/URLParams';
import HeaderTitle from '../../../Components/HeaderTitle';
import AppSearchBox from '../../../Components/AppSearchBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AppModal from '../../../Components/AppModal';
import AppSelect from '../../../Components/Forms/AppSelect';

export default function ReturnDetails({ match }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let query = useQuery();
    const [searchValue, setSearchValue] = useState('');
    const [data, setData] = useState([]);
    
    const [filter, setFilter] = useState({
        isModalOpen: false,
        category: "",
        selectedFilterData: ""
    });

    let { id } = useParams();
    const stateData = useSelector(state => state?.returns?.data?.find(i => i.crn_id === parseInt(id)));
    const filterCategories = useSelector(state => state?.returns?.filter?.categories);
    const permissions = useSelector(state => state?.application?.permissions);

    console.log(permissions, 'permissionspermissionspermissions')

    useEffect(() => {
        dispatch(getCNRDetails(id))
    }, []);

    useEffect(() => {
        let d = stateData?.items;
        if(searchValue) {
            d = stateData?.items?.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(searchValue)))
        }

        if(filter.category) {
            d = stateData.items.filter(i => i.category_id === filter.category)
        }

        setData({
            ...stateData,
            items: d
        })

    }, [stateData]);


    return (
        <AdminHeader goBack={'/returns'} title={
            <HeaderTitle>
                Route - {data?.route_name || '---'} {data?.document_date ?    
                <Moment format="hh:mm A" date={new Date(data?.document_date)} /> : '---'}
            </HeaderTitle>
        } hideAvatar>
            <AppHelmet style={`body {
                    background-color: #f0f8fe;
                }`}/>
            {/* <Fab style={{position: 'absolute', bottom: 70, right: 20}} size="small" color="primary" aria-label="add">
                <ArrowForwardIosIcon />
            </Fab> */}
            <div style={{padding: "15px 10px 10px 10px", display: 'flex'}}>
                <div style={{display: 'flex', width: "100%", fontSize: 14}}>
                    <span style={{marginLeft: 10, marginRight: 10}}><b>{data?.items_count}</b> Items </span> | <span style={{marginLeft: 10}}><b>{data?.quantity_sum}</b> Nos</span> 
                </div>
                <div style={{width: "100%", textAlign: "right"}}>
                    <div style={{display: 'flex', float: 'right'}}>
                        <span style={{color: "#148be7"}} onClick={() => navigate("/dispatch/item/search?id=" + id + "&page=returns&trip_id=" + query.get('trip_id') + "&date=" + query.get('date'))}><b>+ ADD ITEM</b></span>
                        <span style={{marginLeft: 20}}><FilterAltIcon onClick={() => setFilter(p => ({...p, isModalOpen: true}))} /></span>
                    </div>
                </div>
            </div>
            <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
                <AppSearchBox value={searchValue} onChange={(e) => {
                    let value = e.target.value;
                    setSearchValue(value)
                    value = value.toLowerCase();
                    setData({
                        ...data,
                        items: stateData.items.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase()?.includes(value)))
                    })
                }} />
            </div>
            <div>
                <DispatchTwoList parentId={id} data={data?.items} />
            </div>
            {permissions?.returns?.privilege?.includes('W') ? 
                <div style={{position: "fixed", bottom: 10,right: 0,left: 0, textAlign: 'center'}}>
                    <Button variant="contained" size="large" onClick={() => navigate("/returns/create/summary/" + id + "?trip_id=" + query.get('trip_id') + "&date=" + query.get('date'))}>CREATE RRN</Button>
                </div>
            : null }
            <AppModal disablePadding open={filter.isModalOpen} actionButtons={
                <Button onClick={() => {
                    setFilter(p => ({...p, isModalOpen: false, selectedFilterData: filterCategories.find(i => i.id === filter.category)}));
                    setData(p => ({
                        ...p,
                        items: filter.category ? stateData.items.filter(i => i.category_id === filter.category) : stateData.items
                    }))
                }}>
                    Submit
                </Button>
            }>
                <div style={{padding: 25}}>
                    <div><b>Filter</b></div>
                    <div style={{marginTop: 10}}>
                        <AppSelect placeholder="Category" value={filter.category} handleChange={(e) => setFilter(p => ({...p, category: e.target.value}))} size="small" label="Category" options={filterCategories} />
                    </div>
                </div>
            </AppModal>
        </AdminHeader>
    )
}
