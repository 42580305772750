import React, { useState } from 'react';
// import { useNavigate  } from 'react-router-dom';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AppModal from '../../../Components/AppModal';

export default function UpdateItemQty( { open = true, data = {}, close = () => {}, onSubmit = () => {}} ) {

    const [value, setValue] = useState(0);

    return (
        <AppModal disablePadding open={open} actionButtons={
            <div>
                <Button style={{color: 'gray'}} onClick={() => close(p => ({...p, open: false}))}>CANCEL</Button>
                <Button onClick={() => {onSubmit(value)}} autoFocus>DONE</Button>
            </div>
        }>
            <div style={{padding: "10px 10px 5px 20px", borderBottom: "1px solid #dbdbdb", display: "flex"}}>
                <div style={{ marginTop: 10}}>
                    <div style={{marginBottom: -6, color: "#8a8a8a"}}><b>{data.sku_code}</b></div>
                    <div>{data.item_name}</div>
                </div>
            </div>
            <div style={{fontSize: 25, marginTop: 20, marginBottom: 10, color: "#8a8a8a", textAlign: 'center'}}><b>Order: {data.cdo_qty}</b></div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 20}}>
                <div>
                    <RemoveIcon onClick={e => setValue(value > 0 ? value - 1 : 0)} style={{fontSize: 35, marginTop: 10, marginRight: 25, border: "1px solid #ebebeb", borderRadius: 5}} />
                </div>
                <div>
                    <input 
                        value={value} 
                        onChange={e => setValue(e.target.value)} 
                        type="number" 
                        style={{padding: '5px', width: '90px', fontSize: 35, fontWeight: "bold", border: '1px solid #ebebeb', borderRadius: 4, textAlign: "center"}} 
                    />
                </div>
                <div>
                    <AddIcon onClick={e => setValue(value + 1)} style={{fontSize: 35, marginTop: 10, marginLeft: 25, border: "1px solid #ebebeb", borderRadius: 5}} />
                </div>
            </div>
        </AppModal>
    )
}