import React, { useEffect } from 'react';
import AdminHeader from '../../../Templates/AdminHeader';
import AppHelmet from '../../../Components/AppHelmet';
import DispatchTwoList from '../Components/DispatchTwoList';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getVDNDetails } from '../Methods';
import Moment from 'react-moment';
import { useQuery } from '../../../Utility/Hooks/URLParams';
import HeaderTitle from '../../../Components/HeaderTitle';


export default function DispatchDetails({ match }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let query = useQuery();
    
    let { id } = useParams();
    let data = useSelector(state => state?.dispatch?.vdnData?.find(i => i.vdn_id === parseInt(id)));
    
    useEffect(() => {
        dispatch(getVDNDetails(id))
    }, []);


    data = {
        ...data,
        items: data?.items
    }

    return (
        <AdminHeader goBack={'/dispatch'} title={
            <HeaderTitle>
                {data?.document_number}
            </HeaderTitle>
            } hideAvatar>
            <AppHelmet style={`body {
                    background-color: #f0f8fe;
                }`}/>
            {/* <Fab style={{position: 'absolute', bottom: 70, right: 20}} size="small" color="primary" aria-label="add">
                <ArrowForwardIosIcon />
            </Fab> */}
            <div style={{padding: "15px 10px 10px 10px", display: 'flex'}}>
                <div style={{display: 'flex', width: "100%", fontSize: 14}}>
                    <span style={{marginLeft: 10, marginRight: 10}}><b>{data?.items_count}</b> Items </span> | <span style={{marginLeft: 10}}><b>{data?.quantity_sum}</b> Nos</span> 
                </div>
                <div style={{width: "100%", textAlign: "right", color: "#148be7"}}>
                    <span style={{color: "#148be7"}} onClick={() => navigate("/dispatch/item/search?screen=complete&id=" + id + "&page=dispatch/vdn&trip_id=" + query.get('trip_id') + "&date=" + query.get('date'))}><b>+ ADD ITEM</b></span>
                </div>
            </div>
            <div>
                <DispatchTwoList type="vdn" parentId={id} data={data.items} />
            </div>
            <div style={{position: "fixed", bottom: 10,right: 0,left: 0, textAlign: 'center'}}>
                <Button variant="contained" size="large" onClick={() => navigate("/dispatch/update/vdn/summary/" + id + "?trip_id=" + query.get('trip_id') + "&date=" + query.get('date'))}>UPDATE VDN</Button>
            </div>
        </AdminHeader>
    )
}