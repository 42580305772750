import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: [],
  vdnData: [],
  trip_categories: [],
  filter: {
    categories: []
  }
}

export const slice = createSlice({
  name: 'cdo',
  initialState,
  reducers: {
    getItems: (state, action) => {
      if(state.data.length > 0) {
        state.data = action.payload.map(item => {
          let d = state.data.find(i => i.cdo_id === item.cdo_id)
          if(d) {
            return {...item, ...d}
          }
          return item
        })
      }else {
        state.data = action.payload
      }
      
      return state;
    },
    getTripCategoryReducer: (state, action) => {
      state.trip_categories = action.payload
      return state;
    },
    getItemDetails: (state, action) => {
      if(state.data.length > 0) {
        state.data = state.data.map(item => {
          if(item.cdo_id === parseInt(action.payload.id)) {
            action.payload.data.items?.map(i => ({...i, qty: 0}))
            let itemSet = action.payload.data.items.map(i => {
              let d = item?.items?.find(ii => ii.id === i.id)
              if(d) {
                return {...i, ...d}
              }
              return {
                ...i,
                qty: i.cdo_qty
              }
            });
            itemSet = item?.items ? item?.items?.map(i => {
              let d = itemSet.find(ii => ii.id === i.id)
              if(d) {
                return {...i, ...d}
              }
              return i
            }) : itemSet;
            return {
              ...item, 
              ...action.payload.data,
              items: itemSet
            }
          }
          return item
        });
      }else {
        let d = {
          ...action.payload.data,
          items: action.payload.data.items?.map(i => ({...i, qty: i.cdo_qty}))
        };
        state.data = [d] 
      }

      //Set filter categories
      state.filter.categories = action.payload.data.items.map(item => ({id: item.category_id, label: item.category_name}))
      .filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id && t.label === value.label
        ))
      )

      return state;
    },
    setVDNDetails: (state, action) => {
      if(state.vdnData.length > 0) {
        state.vdnData = state.vdnData.map(item => {
          if(item.vdn_id === parseInt(action.payload.id)) {
            action.payload.data.items?.map(i => ({...i, qty: 0}))
            let itemSet = action.payload.data.items.map(i => {
              let d = item?.items?.find(ii => ii.id === i.id)
              if(d) {
                return {...i, ...d}
              }
              return {
                ...i,
                qty: i.issue_qty
              }
            });
            itemSet = item?.items ? item?.items?.map(i => {
              let d = itemSet.find(ii => ii.id === i.id)
              if(d) {
                return {...i, ...d}
              }
              return i
            }) : itemSet;
            return {
              ...item, 
              ...action.payload.data,
              items: itemSet
            }
          }
          return item
        });
      }else {
        let d = {
          ...action.payload.data,
          items: action.payload.data.items?.map(i => ({...i, qty: i.issue_qty}))
        };
        state.vdnData = [d] 
      }

      return state;
    },
    updateVDNNumber: (state, action) => {

      if(state.data.length > 0) {
        state.data = state.data.map(item => {
          if(item.cdo_id === parseInt(action.payload.id)) {
            return {
              ...item,
              vdn_number: action.payload.vdn_number
            }
          }
          return item
        });
      }

      return state;

    },
    updateItemQty: (state, action) => {

      state.data = state.data.map(item => {
        if(item.cdo_id === parseInt(action.payload.parentId)) {
          item.items = item.items.map(i => {
            if(i.item_id === parseInt(action.payload.item.item_id)) {
              i.qty = parseInt(action.payload.quantity)
            }
            return i
          })
        }
        return item;
      });
      
      return state;
    },
    updateItemQtyVDN: (state, action) => {
      state.vdnData = state.vdnData.map(item => {
        if(item.vdn_id === parseInt(action.payload.parentId)) {
          item.items = item.items.map(i => {
            if(i.item_id === parseInt(action.payload.item.item_id)) {
              i.qty = parseInt(action.payload.quantity)
            }
            return i
          })
        }
        return item;
      });
      
      return state;
    },
    setSelectedItemsVdn(state, action) {
      state.vdnData = state.vdnData.map(value => {
        if(value.vdn_id === parseInt(action.payload.id)) {
          let item = value.items.find(i => i.item_id === action.payload?.data?.item_id)
          if(item) {
            value.items = value.items.map(i => {
              if(i.item_id === action.payload?.data?.item_id){
                return {
                  ...i,
                  qty: i.qty + action.payload?.data.qty,
                  pick_list_id: action.payload.id
                }
              }
              return i;
            });
          }else {
            return {
              ...value,
              items: [{
                ...action.payload.data,
                cdo_qty: 0,
                pick_list_id: action.payload.id
              }, ...value.items]
            }
          }
        }

        return value;
      });          
      
      return state;
    },
    setSelectedItems(state, action) {
      state.data = state.data.map(value => {
        if(value.cdo_id === parseInt(action.payload.id)) {
          let item = value.items.find(i => i.item_id === action.payload?.data?.item_id)
          if(item) {
            value.items = value.items.map(i => {
              if(i.item_id === action.payload?.data?.item_id){
                return {
                  ...i,
                  qty: i.qty + action.payload?.data.qty
                }
              }
              return i;
            });
          }else {
            return {
              ...value,
              items: [{
                ...action.payload.data,
                cdo_qty: 0,
              }, ...value.items]
            }
          }
        }

        return value;
      });          
      
      return state;
    },
    getVDNItems: (state, action) => {
      if(state.vdnData.length > 0) {
        state.vdnData = action.payload.map(item => {
          let d = state.vdnData.find(i => i.vdn_id === item.vdn_id)
          if(d) {
            return {...item, ...d}
          }
          return item
        })
      }else {
        state.vdnData = action.payload
      }
      
      return state;

    }
  },
  
})

// Action creators are generated for each case reducer function
export const { getVDNItems, getItems, setSelectedItemsVdn, setVDNDetails, setSelectedItems, updateItemQtyVDN, getTripCategoryReducer, getItemDetails, updateItemQty, updateVDNNumber } = slice.actions

export default slice.reducer