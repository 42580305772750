import React from 'react';
import { makeStyles } from '@mui/styles';
import { useNavigate  } from 'react-router-dom';
import Moment from 'react-moment';

const { innerHeight: height } = window;

const useStyles = makeStyles({
    card: {
        background: '#fff', 
        marginBottom: 8, 
        borderRadius: 5,
    },
});

export default function ToDeliverTabs({ data = [], date = "", tripId="", hideViewRedirection = false, viewType = "dispatch" }) {

    const classes = useStyles();
    const navigate = useNavigate();

    const handleViewRedirect = (value) => {
        if(viewType === "dispatch") return navigate('/returns/' +  value.crn_id + '?trip_id=' + tripId + '&date=' + date);
        return navigate('/returns/vdn/' +  value.rrn_id + '?trip_id=' + tripId + '&date=' + date);
    }

    return (
        <div style={{padding: '15px 15px 15px 15px', height: height, overflow: 'scroll'}}>
            {!data.length > 0 ? <div style={{marginTop: "30%", textAlign: 'center'}}>No items found</div> : null}
            {data.map((value, index) => (
                <div key={index} className={classes.card}>
                    <div onClick={() => hideViewRedirection ? null : handleViewRedirect(value)} style={{padding: 15, borderBottom: '1px solid #e1e1e1'}}>
                        <div style={{fontSize: 25, fontWeight: 'bold'}}>
                            <span style={{ color: '#0078cf'}}>{value.route_name}</span>
                            <span style={{ color: '#7f7f7f', marginLeft: 20}}> <Moment date={value.document_date} format="hh:mm A" /></span>
                            <span style={{float: 'right', textAlign: 'right', fontSize: 12, width: '150px', fontWeight: 'normal', marginTop: 8, color: "#0078cf"}}>{value.salesman.split(' ')[0]}</span>
                        </div>
                        <div style={{fontSize: "small", display: 'flex'}}>
                            <div style={{width: "100%"}}>{viewType === "dispatch" ? "Order" : "Delivered"} <b>{value.items_count}</b> items | <b>{value.quantity_sum}</b> Nos</div>
                            {/* <div style={{width: "100%", textAlign: 'right'}}>Order: <b>265</b> items | <b>3004</b> Nos</div> */}
                        </div>
                        <div style={{fontSize: "small", marginTop: 2, marginBottom: "-5px"}}>
                            {/* <div>Difference: <span style={{color: "#56bf49"}}><b>352</b></span> items | <span style={{color: "#e13935"}}><b>4899</b></span> Nos</div> */}
                        </div>
                    </div>
                    <div style={{padding: '5px 5px 5px 15px', fontSize: "small"}}>
                        <span>CNR</span>
                        <span style={{marginLeft: 20, color: "#6ab4f5"}}>{value.vdn_number ?? '---'}</span>
                        {/* <span style={{float: "right", marginRight: 10}}>Shibudeen</span> */}
                    </div>
                </div>
            ))}
        </div>
    )
}