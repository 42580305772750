import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    searchBox: {
        padding: '10px', 
        width: '100%', 
        border: '1px solid #bfbfbf',
        borderRadius: '3px',
        fontSize: '16px',
        '&:focus': {
            outline: 'none',
        }
    },
});

export default function AppSearchBox({ placeholder = "Search...", onChange = () => {}, value = "" }) {

    const classes = useStyles();

    return (
        <div>
            <input 
                type="text" 
                onChange={onChange}
                className={classes.searchBox} 
                placeholder={placeholder} 
                value={value}
            />
        </div>
    )
}