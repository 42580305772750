
import { AppRequest } from '../../../Services/AppRequest';
import { initNotification } from '../../ApplicationReducer';
import { API } from '../Constant';
import { getItems, setVDNDetails, getTripCategoryReducer, getItemDetails, updateItemQty, updateVDNNumber, setSelectedItems, getVDNItems, updateItemQtyVDN, setSelectedItemsVdn } from '../Reducer';

export const updateVDN = (id, data = "") => (dispatch) => {
    return new Promise((resolve, reject) => {
        return AppRequest(API.UPDATE_VDN, data, "POST")
        .then(res => {
            if(res.data.data) {
                dispatch(updateVDNNumber({id, vdn_number: res.data.data.document_number}));
                return resolve(res)
            }

            dispatch(initNotification({open: true, message: "Document not saved! Please contact admin.", type: "error"}));

        })
        .catch(error => {
            reject(error)
        })
    });
}

export const getCDO = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return AppRequest(API.GET_CDO + data)
        .then(res => {
            dispatch(getItems(res.data));
            dispatch(initNotification({open: false, message: "", type: "loading"}));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const getVDN = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return AppRequest(API.GET_VDN + data)
        .then(res => {
            dispatch(getVDNItems(res.data));
            // dispatch(initNotification({open: false, message: "", type: "loading"}));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const getCDODetails = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(initNotification({open: true, message: "Fetching...", type: "loading"}));
        return AppRequest(API.GET_CDO_DETAILS + "?cdo_id=" + id)
        .then(res => {
            dispatch(initNotification({open: false, message: "", type: "loading"}));
            dispatch(getItemDetails({id, data: res.data}));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const getVDNDetails = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(initNotification({open: true, message: "Fetching...", type: "loading"}));
        return AppRequest(API.GET_VDN_DETAILS + "?vdn_id=" + id)
        .then(res => {
            dispatch(initNotification({open: false, message: "", type: "loading"}));
            dispatch(setVDNDetails({id, data: res.data}));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const getTripsCategory = (data = "") => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(initNotification({open: true, message: "Fetching...", type: "loading"}));
        return AppRequest(API.GET_TRIP_CATEGORY + data)
        .then(res => {
            dispatch(getTripCategoryReducer(res.data));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const createVDN = (id, data = "") => (dispatch) => {
    return new Promise((resolve, reject) => {
        return AppRequest(API.CREATE_VDN, data, "POST")
        .then(res => {
            if(res.data.data) {
                dispatch(updateVDNNumber({id, vdn_number: res.data.data.document_number}));
                return resolve(res)
            }

            dispatch(initNotification({open: true, message: "Document not saved! Please contact admin.", type: "error"}));

        })
        .catch(error => {
            reject(error)
        })
    });
}

export const updateItemQuantityMethod = (data = "") => (dispatch) => {
    dispatch(updateItemQty(data))
}

export const updateItemQuantityMethodVDN = (data) => (dispatch) => {
    dispatch(updateItemQtyVDN(data))
}

export const mergeSelectedItem = (id, data = "", screen = "") => (dispatch) => {
    if(screen === "complete") {
        dispatch(setSelectedItemsVdn({id, data}))
    }else {
        dispatch(setSelectedItems({id, data}))
    }
        
}