import React, { useEffect, useState } from 'react';
import AdminHeader from '../../../Templates/AdminHeader';
import { useSelector, useDispatch } from 'react-redux';
import { getSearchItems, selectItem } from '../Methods';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { mergeSelectedItem } from '../../Dispatch/Methods';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../Utility/Hooks/URLParams';
import UpdateItemQty from '../../Dispatch/Components/UpdateItemQty';

const { innerHeight: height } = window;

export default function Search() {

    const dispatch = useDispatch();

    const data = useSelector(state => state.searchItems.data);
    const [itemData, setItemData] = useState([]);
    const navigate = useNavigate();
    let query = useQuery();

    const [selectedData, setSelectedData] = useState("");
    const [openModal, setOpenModal] = useState(false)

    useEffect(() => {

        dispatch(getSearchItems())

    }, [])

    useEffect(() => {
        setItemData(data)
    }, [data]);

    const handleSelectItem = (value) => {
        value = {
            ...value,
            from: "search"
        }
        dispatch(mergeSelectedItem(query.get('id'), value, query.get('screen')))
        return navigate('/'+query.get('page')+'/'+query.get('id')+'?trip_id='+query.get('trip_id')+'&date=' + query.get('date'));
    }


    return (
        <div>
            <AdminHeader>
                <div style={{background: "#fff", marginBottom: 2}}>
                    <div style={{padding: 14}}>
                        <input onChange={(e) => {
                            const value = e.target.value;
                            setItemData(value ? itemData.filter(item => item.short_code.toLowerCase().includes(value.toLowerCase())) : data)

                        }} style={{padding: '11px', fontSize: "16px", width: '100%', border: '1px solid #d1d1d1', borderRadius: '5px', height: '43px'}} type="text" placeholder="Search"/>
                    </div>
                </div>
                <div style={{height: height / 1.08, overflow: 'scroll'}}>
                    {itemData.map((value, index) => (
                        <div onClick={() => {
                            setSelectedData(value);
                            setOpenModal(true)
                        }} key={index} style={{background: "#fff", marginBottom: 1}}>
                            <div style={{padding: '16px', display: 'flex'}}>
                                <div style={{width: "100%"}}>
                                    <div>{value.short_code}</div>
                                    <div>{value.item_name}</div>
                                </div>
                                <div>{value.selected ? <CheckCircleIcon style={{color: "#5eba00"}} /> : null }</div>
                            </div>
                        </div>
                    ))}
                </div>
            </AdminHeader>
            <UpdateItemQty data={{
                sku_code: selectedData.sku_code,
                cdo_qty: 0,
                item_name: selectedData.item_name
            }} 
            close={() => { setSelectedData(""); setOpenModal(false)}} 
            onSubmit={e => {
                handleSelectItem({
                    ...selectedData,
                    qty: e
                });
                setOpenModal(false)
            }}
            open={openModal} />
        </div>
    )
}