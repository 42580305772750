import React from 'react';
import { makeStyles } from '@mui/styles';

const { innerHeight: height } = window;

const useStyles = makeStyles({
    card: {
        background: '#fff', 
        '&:hover': {
            background: 'rgb(25 118 210)',
            color: '#fff',
        }
    },
});

export default function DispatchSummaryList({ data }) {

    const classes = useStyles();//

    return (
        <div style={{padding: '1px 0 10px 0', height: "100vh", overflow: 'scroll'}}>
            {data.map((value, index) => (
                <div key={index} className={classes.card} style={{padding: 15, marginBottom: 0.2, borderBottom: '1px solid #e1e1e1', borderRadius: 5}}>
                    <div style={{display: 'flex'}}>
                        {/* <div>
                            <div style={{fontSize: 23}}><b>40{index}</b></div>
                        </div> */}
                        <div style={{ width: "100%"}}>
                            <div>
                                <div style={{color: '#b2b2b2'}}><b>{value.sku_code}</b></div>
                                <div style={{marginTop: -5}}>{value.item_name}</div>
                            </div>
                            <div style={{marginTop: -5}}>Order: {value.crn_qty} | Delivery: {value.qty}</div>
                        </div>
                        <div style={{border: "1px solid #dcdcdc", color: value.crn_qty >= value.qty ? 'red': 'green', borderRadius: 5, width: "20%", textAlign: 'center', paddingTop: 8, fontSize: 25}}><b>{value.crn_qty <= value.qty ? '+' : null}{value.qty - value.crn_qty}</b></div>
                    </div>
                </div>
            ))}
        </div>
    )
}