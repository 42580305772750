import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function AppHelmet({ style }) {

    return (
        <Helmet style={[{
            "cssText": `${style}`
        }]}>
            <title></title>
        </Helmet>
    )
}