import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
// import { Alert } from '@mui/material';

export default function PositionedSnackbar() {

    const notify = useSelector(state => state.application);

    if(notify.type === "loading")
        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: notify.vertical, horizontal: notify.horizontal }}
                    open={notify.open}
                    // onClose={handleClose}
                    message={<div style={{display: 'flex'}}>
                        <CircularProgress style={{color: "#fff", marginRight: 5}} size={20} /> <div style={{fontSize: 14}}>{notify.message}</div>
                    </div>}
                    // key={notify.vertical + notify.horizontal}
                />
            </div>
        );

    return null
}
