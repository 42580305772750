import React, { useState } from 'react';
import AdminHeader from '../../../Templates/AdminHeader';
import { Button } from '@mui/material';
import AppHelmet from '../../../Components/AppHelmet';
import DispatchSummaryList from '../Components/DispatchSummaryList';
import AppModal from '../../../Components/AppModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Moment from 'react-moment';
import { updateVDN } from '../Methods';
import { useQuery } from '../../../Utility/Hooks/URLParams';
import HeaderTitle from '../../../Components/HeaderTitle';

export default function DispatchSummary() {

    const dispatch = useDispatch();
    let query = useQuery();
    

    let { id } = useParams();
    const data = useSelector(state => state?.dispatch?.vdnData.find(i => i.vdn_id === parseInt(id)));

    const navigate = useNavigate();
    const [successModal, setSuccessModal] = useState(false);
    const [completedSummery, setCompletedSummary] = useState("");

    const handleSubmit = () => {
        setSuccessModal(true)
        dispatch(updateVDN(id, data.items.map(i => ({
            pick_list_id: +id,
            id: i.id,
            item_id: i.item_id,
            uom_id: i.measurement_id,
            cdo_qty: parseFloat(i.cdo_qty),
            issue_qty: parseFloat(i.qty),
            variance_qty: i.qty - i.cdo_qty
        }))))
        .then(res => {
            setCompletedSummary(res.data.data)
        })
        
    }

    return (
            <AdminHeader goBack={`/dispatch/vdn/${id}?trip_id=${query.get('trip_id')}&date=${query.get('date')}`} title={

                <HeaderTitle>
                    Route - {data?.route_name || '---'} {data?.document_date ?    
                    <Moment format="hh:mm A" date={new Date(data?.document_date)} /> : '---'}
                </HeaderTitle>

                }
                
             hideAvatar>
                 
            {! data || (!data?.items.filter(i => i.qty !== 0).length > 0) ? <div style={{textAlign: 'center', marginTop: "50%"}}>No items found!</div> :
                <div>
                    <AppHelmet style={`body {
                        background-color: #f0f8fe;
                    }`}/>
                <div style={{padding: "12px 10px 10px 10px", display: 'flex'}}>
                    <div style={{fontSize: 16}}>Variations from Order</div>
                </div>
                <div>
                    <DispatchSummaryList 
                    data={
                        data?.items
                        .filter(i => (i.qty - i.cdo_qty) !== 0)
                    } />
                </div>
                <div style={{position: "fixed", bottom: 10,right: 0,left: 0, textAlign: 'center'}}>
                    <Button onClick={handleSubmit} variant="contained" size="large">CONFIRM & UPDATE VDN</Button>
                </div>
                <AppModal disablePadding open={successModal} actionButtons={completedSummery ? 
                    <div>
                        <Button onClick={() => navigate("/dispatch")}>
                            Okay
                        </Button>
                    </div>
                : null}>
                    {!completedSummery ? 
                        <div style={{textAlign: 'center', padding: 25, paddingTop: 50}}>
                            <CircularProgress size={50}/>
                            <div>Creating...</div>
                        </div>
                    : 
                        <div style={{textAlign: 'center', padding: 20}}>
                            <CheckCircleIcon style={{fontSize: 50, color: "#5bc319"}} />
                            <div style={{color: "#4f99fa", marginTop: 15}}><b>{completedSummery.document_number}</b></div>
                            <div>VDN updated successfully</div>
                            <div style={{fontSize: 35}}><b>{data?.route_name}</b></div>
                            <div style={{color: "#acacac"}}>{completedSummery.no_of_items} Items</div>
                        </div> 
                    }
                </AppModal> 
            </div>}
        </AdminHeader>
    )
}
