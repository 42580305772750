import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: [],
  rrnData: [],
  trip_categories: [],
  filter: {
    categories: []
  }
}

export const slice = createSlice({
  name: 'cdo',
  initialState,
  reducers: {
    getItems: (state, action) => {
      if(state.data.length > 0) {
        state.data = action.payload.map(item => {
          let d = state.data.find(i => i.crn_id === item.crn_id)
          if(d) {
            return {...item, ...d}
          }
          return item
        })
      }else {
        state.data = action.payload
      }
      
      return state;
    },
    getTripCategoryReducer: (state, action) => {
      state.trip_categories = action.payload
      return state;
    },
    getItemDetails: (state, action) => {
      if(state.data.length > 0) {
        state.data = state.data.map(item => {
          if(item.crn_id === parseInt(action.payload.id)) {
            action.payload.data.items?.map(i => ({...i, qty: 0}))
            let itemSet = action.payload.data.items.map(i => {
              let d = item?.items?.find(ii => ii.id === i.id)
              if(d) {
                return {...i, ...d}
              }
              return {
                ...i,
                qty: 0
              }
            });
            itemSet = item?.items ? item?.items?.map(i => {
              let d = itemSet.find(ii => ii.id === i.id)
              if(d) {
                return {...i, ...d}
              }
              return i
            }) : itemSet;
            return {
              ...item, 
              ...action.payload.data,
              items: itemSet
            }
          }
          return item
        });
      }else {
        let d = {
          ...action.payload.data,
          items: action.payload.data.items?.map(i => ({...i, qty: 0}))
        };
        state.data = [d] 
      }

      //Set filter categories
      state.filter.categories = action.payload.data.items.map(item => ({id: item.category_id, label: item.category_name}))
      .filter((value, index, self) =>
        index === self.findIndex((t) => (
          t.id === value.id && t.label === value.label
        ))
      )

      return state;
    },
    setRRNDetails: (state, action) => {
      if(state.rrnData.length > 0) {
        state.rrnData = state.rrnData.map(item => {
          if(item.rrn_id === parseInt(action.payload.id)) {
            action.payload.data.items?.map(i => ({...i, qty: 0}))
            let itemSet = action.payload.data.items.map(i => {
              let d = item?.items?.find(ii => ii.id === i.id)
              if(d) {
                return {...i, ...d}
              }
              return {
                ...i,
                qty: 0
              }
            });
            itemSet = item?.items ? item?.items?.map(i => {
              let d = itemSet.find(ii => ii.id === i.id)
              if(d) {
                return {...i, ...d}
              }
              return i
            }) : itemSet;
            return {
              ...item, 
              ...action.payload.data,
              items: itemSet
            }
          }
          return item
        });
      }else {
        let d = {
          ...action.payload.data,
          items: action.payload.data.items?.map(i => ({...i, qty: 0}))
        };
        state.rrnData = [d] 
      }

      return state;
    },
    updateVDNNumber: (state, action) => {

      console.log(action, 'actionactionactionaction')

      if(state.data.length > 0) {
        state.data = state.data.map(item => {
          if(item.crn_id === parseInt(action.payload.id)) {
            return {
              ...item,
              vdn_number: action.payload.vdn_number
            }
          }
          return item
        });
      }

      return state;

    },
    updateItemQty: (state, action) => {

      state.data = state.data.map(item => {
        if(item.crn_id === parseInt(action.payload.parentId)) {
          item.items = item.items.map(i => {
            if(i.item_id === parseInt(action.payload.item.item_id)) {
              i.qty = parseInt(action.payload.quantity)
            }
            return i
          })
        }
        return item;
      });
      
      return state;
    },
    setSelectedItems(state, action) {
      state.data = state.data.map(value => {
        if(value.crn_id === parseInt(action.payload.id)) {
          let item = value.items.find(i => i.item_id === action.payload?.data?.item_id)
          if(item) {
            value.items = value.items.map(i => {
              if(i.item_id === action.payload?.data?.item_id){
                return {
                  ...i,
                  qty: i.qty + action.payload?.data.qty
                }
              }
              return i;
            });
          }else {
            return {
              ...value,
              items: [{
                ...action.payload.data,
                crn_qty: 0,
              }, ...value.items]
            }
          }
        }

        return value;
      });          
      
      return state;
    },
    getRRNItems: (state, action) => {
      if(state.rrnData.length > 0) {
        state.rrnData = action.payload.map(item => {
          let d = state.rrnData.find(i => i.rrn_id === item.rrn_id)
          if(d) {
            return {...item, ...d}
          }
          return item
        })
      }else {
        state.rrnData = action.payload
      }
      
      return state;

    }
  },
  
})

// Action creators are generated for each case reducer function
export const { getRRNItems, getItems, setRRNDetails, setSelectedItems, getTripCategoryReducer, getItemDetails, updateItemQty, updateVDNNumber } = slice.actions

export default slice.reducer