// import { Grid } from '@mui/material';
import React from 'react';

export default function AdminLayout({ children }) {

    return (
        <div>
            {children}
        </div>
    )
}