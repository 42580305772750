import { Card, Container } from '@mui/material';
import React from 'react';
import AppLogo from '../../../Components/AppLogo';

export default function Layouts({ children, title = "", subTitle = "" }) {

    return (
        <Container>
            <div style={{paddingTop: "15%"}}>
                <div style={{padding: 10}}>
                    <Card>
                        <div style={{padding: 50, textAlign: 'center'}}>
                            <AppLogo height={90} />
                            <div style={{marginTop: 10}}>
                                <div style={{fontSize: 25, fontWeight: "bold", color: "#29a8e0", letterSpacing: -1}}>ACB BAKEWELL</div>
                                <div style={{fontSize: 20}}>Please sign in to continue</div>
                            </div>
                        </div>
                        <div style={{padding: "0px 30px 50px 30px"}}>
                            {children}
                        </div>
                    </Card>
                </div>
            </div>
        </Container>
    )
}