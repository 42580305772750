import React, { useEffect, useState } from 'react';
import AdminHeader from '../../../Templates/AdminHeader';
import AppHelmet from '../../../Components/AppHelmet';
import DispatchOneList from '../Components/DispatchOneList';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { getCDODetails } from '../Methods';
import Moment from 'react-moment';
import { useQuery } from '../../../Utility/Hooks/URLParams';
import HeaderTitle from '../../../Components/HeaderTitle';
import AppSearchBox from '../../../Components/AppSearchBox';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import AppModal from '../../../Components/AppModal';
import AppSelect from '../../../Components/Forms/AppSelect';

export default function DispatchDetails({ match }) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    let query = useQuery();
    
    let { id } = useParams();
    // const ssss = useSelector(state => state?.dispatch?.data);
    const stateData = useSelector(state => state?.dispatch?.data?.find(i => i.cdo_id === parseInt(id)));
    const filterCategories = useSelector(state => state?.dispatch?.filter?.categories);
    const permissions = useSelector(state => state?.application?.permissions);

    const [data, setData] = useState([])
    const [searchValue, setSearchValue] = useState('');
    const [filter, setFilter] = useState({
        isModalOpen: false,
        category: "",
        selectedFilterData: ""
    });

    useEffect(() => {
        dispatch(getCDODetails(id))
    }, []);

    const handleClearFilter = () => {
        setFilter(p => ({...p, isModalOpen: false, category: "", selectedFilterData: ""}));
        setData(stateData)
    }

    useEffect(() => {
        let d = stateData?.items;
        if(searchValue) {
            d = stateData?.items?.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes('AB002')))
        }

        if(filter.category) {
            d = stateData.items.filter(i => i.category_id === filter.category)
        }

        setData({
            ...stateData,
            items: d
        })

    }, [stateData]);

    

    return (
        <AdminHeader goBack={`/dispatch`} title={
            <HeaderTitle>
                Route - {data?.route_name || '---'} {data?.document_date ?    
                <Moment format="hh:mm A" date={new Date(data?.document_date)} /> : '---'}
            </HeaderTitle>
        } hideAvatar>
            <AppHelmet style={`body {
                    background-color: #f0f8fe;
                }`}/>
            {/* <Fab style={{position: 'absolute', bottom: 70, right: 20}} size="small" color="primary" aria-label="add">
                <ArrowForwardIosIcon />
            </Fab> */}
            <div style={{padding: "15px 10px 1px 10px", display: 'flex'}}>
                <div style={{display: 'flex', width: "100%", fontSize: 14}}>
                    <span style={{marginLeft: 10, marginRight: 10}}><b>{data?.items_count}</b> Items </span> | <span style={{marginLeft: 10}}><b>{data?.quantity_sum}</b> Nos</span> 
                </div>
                <div style={{width: "100%", textAlign: "right"}}>
                    <div style={{display: 'flex', float: 'right'}}>
                        <span style={{color: "#148be7"}} onClick={() => navigate("/dispatch/item/search?id=" + id + "&page=dispatch&trip_id=" + query.get('trip_id') + "&date=" + query.get('date'))}><b>+ ADD ITEM</b></span>
                        <span style={{marginLeft: 20}}><FilterAltIcon onClick={() => setFilter(p => ({...p, isModalOpen: true}))} /></span>
                    </div>
                </div>
            </div>
            {(filter?.selectedFilterData) ? <div style={{ paddingLeft: 20, marginBottom: 10}}>Filter by category ({data?.items?.length}): {filter?.selectedFilterData?.label} <span style={{marginLeft: 10}} onClick={handleClearFilter}><u>Clear</u></span></div> : null}
            
            <div style={{ paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
                <AppSearchBox value={searchValue} onChange={(e) => {
                    let value = e.target.value;
                    setSearchValue(value)
                    value = value.toLowerCase();
                    setData({
                        ...data,
                        items: stateData.items.filter(entry => Object.values(entry).some(val => typeof val === "string" && val.toLowerCase().includes(value)))
                    })
                }} />
            </div>
            <div>
                {/* .slice().sort((a, b) => a.qty < b.qty ? 1 : -1) */}
                <DispatchOneList parentId={id} data={data?.items} />
            </div>
            {permissions?.dispatch?.privilege?.includes('W') ? 
            <div style={{position: "fixed", bottom: 10,right: 0,left: 0, textAlign: 'center'}}>
                <Button variant="contained" size="large" onClick={() => navigate("/dispatch/create/summary/" + id + "?trip_id=" + query.get('trip_id') + "&date=" + query.get('date'))}>CREATE VDN</Button>
            </div>
            : null }
            <AppModal disablePadding open={filter.isModalOpen} actionButtons={
                <Button onClick={() => {
                    setFilter(p => ({...p, isModalOpen: false, selectedFilterData: filterCategories.find(i => i.id === filter.category)}));
                    setData(p => ({
                        ...p,
                        items: filter.category ? stateData.items.filter(i => i.category_id === filter.category) : stateData.items
                    }))
                }}>
                    Submit
                </Button>
            }>
                <div style={{padding: 25}}>
                    <div><b>Filter</b></div>
                    <div style={{marginTop: 10}}>
                        <AppSelect placeholder="Category" value={filter.category} handleChange={(e) => setFilter(p => ({...p, category: e.target.value}))} size="small" label="Category" options={filterCategories} />
                    </div>
                </div>
            </AppModal>
        </AdminHeader>
    )
}