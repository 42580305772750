import React, { useEffect, useState, useCallback } from 'react';
import AdminHeader from '../../../Templates/AdminHeader';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PageContainer from '../../../Components/PageContainer';
import { Grid, Menu, MenuItem } from '@mui/material';
import AppTabs from '../../../Components/AppTabs';
import ToDeliverTabs from '../Components/ToDeliverTabs';
import AppHelmet from '../../../Components/AppHelmet';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import { useDispatch, useSelector } from 'react-redux';
import { getCDO, getTripsCategory, getVDN } from '../Methods';
import AppDatePicker from '../../../Components/AppDatePicker';
import Moment from 'react-moment';
import HomeIcon from '@mui/icons-material/Home';

export default function Login() {

    const dispatch = useDispatch();
    const data = useSelector(state => state?.dispatch?.data);
    const vdnData = useSelector(state => state?.dispatch?.vdnData);
    const tripCategories = useSelector(state => state?.dispatch?.trip_categories);

    const [anchorEl] = useState(null);
    const [tripCategory, setTripCategory] = useState({
        open: false,
        selected: null,
    });

    const [datePicker, setDatePicker] = useState({
        open: false,
        selected: new Date(),
    });

    const fetchData = useCallback((tripId = "", date = "") => {
        dispatch(getCDO(`?trip_category_id=${tripId || tripCategory?.selected?.id}&serving_date=${Math.floor(new Date(date || datePicker?.selected) / 1000)}`))
        dispatch(getVDN(`?trip_category_id=${tripId || tripCategory?.selected?.id}&serving_date=${Math.floor(new Date(date || datePicker?.selected) / 1000)}`))
    }, [])

    useEffect(() => {
        dispatch(getTripsCategory())
        .then(res => {
            setTripCategory(p => ({...p, selected: res.data[0]}))
            fetchData(res.data[0].id)
        })
    }, [])

    return (
        <div>
            <AdminHeader title={
                <div style={{textAlign: 'center', width: "100%",fontSize: '24px', fontWeight: '600'}}>DISPATCH</div>
            } goBackIcon={<HomeIcon style={{fontSize: 30}} />} goBack={`/dashboard`}>
                <div style={{display: 'none'}}>
                    <AppDatePicker 
                        onChange={e => { 
                            setDatePicker(p => ({...p, selected: e}))
                            fetchData(tripCategory?.selected?.id, e)
                        }} 
                        open={datePicker.open} 
                        close={e => setDatePicker(p => ({...p, open: false}))} 
                    />
                </div>
                <AppHelmet style={`body {
                        background-color: #f0f8fe;
                    }`}/>
                <PageContainer background="#f6f6f6">
                    <Grid container spacing={0}>
                        <Grid item xs={6}>
                            <div style={{display: 'flex', marginTop: 5}} onClick={e => setDatePicker(p => ({...p, open: true}))}><CalendarMonthIcon style={{marginRight: 10}} /> <span><Moment date={datePicker.selected} format="ddd, DD MMM" /></span></div>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{float: 'right'}}>
                                <div onClick={e => setTripCategory(p => ({...p, open: true}))} style={{display: 'flex', marginTop: -8}}>
                                    <DirectionsCarFilledIcon style={{paddingTop: 10, fontSize: 35, marginRight: 2}} />
                                    <span style={{paddingTop: 14}}>{tripCategory?.selected?.serving_trip_name}</span>
                                </div>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={tripCategory.open}
                                    // onClose={handleClose}
                                    // onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 3,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform: 'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    {tripCategories.map((value, index) => <MenuItem onClick={e => {
                                        setTripCategory(p => ({...p, open: false, selected: value}))
                                        fetchData(value.id, datePicker.selected)

                                    }} key={index}>{value.serving_trip_name}</MenuItem>)}
                                </Menu>
                            </div>
                        </Grid>
                    </Grid>
                    
                </PageContainer>
                <div>
                    <AppTabs background="#fff" tabs={[
                        {
                            label: "To Deliver",
                            component: <ToDeliverTabs 
                            date={Math.floor(new Date(datePicker.selected) / 1000)} 
                            tripId={tripCategory?.selected?.id} 
                            data={data.filter(i => i.vdn_id === 0)} 
                            // .filter(i => i.vdn_id === 0)
                            />
                        },
                        {
                            label: "Completed",
                            component: <ToDeliverTabs 
                                viewType="vdn" 
                                date={Math.floor(new Date(datePicker.selected) / 1000)} 
                                tripId={tripCategory?.selected?.id} 
                                data={vdnData.map(i => ({...i, vdn_number: i.document_number}))} 
                            />
                        }
                    ]} />
                </div>
            </AdminHeader>
        </div>
    )
}
