import axios from 'axios';

export const AppRequest = (url, body = "", method = "GET", other = {}) => {
    return new Promise((resolve, reject) => {
        var config = { 
            method: method, 
            data: body, url: process.env.REACT_APP_API_URL + url, 
            withCredentials: false,
            headers: { 
                'Content-Type': 'application/json' 
            }};
        axios(config)
        .then(function (response) {
            resolve(response);
        })
        .catch(function (error) {
            reject(error);
        });
    });
}