import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";

//Modules
import * as Auth from './Modules/Auth/Containers';
import * as Dashboard from './Modules/Dashboard/Containers';
import * as Dispatch from './Modules/Dispatch/Containers';
import * as Returns from './Modules/Returns/Containers';
import * as ItemSearch from './Modules/ItemSearch/Containers';

// import { Redirect } from 'react-router-dom';
import AdminLayout from './Templates/AdminLayout';
import AppNotification from './Components/AppNotification'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { InitPermissions } from './Modules/ApplicationReducer';

const PrivateRoute = ({ children }) => {
    let isAuthenticated = sessionStorage.getItem('auth_user');
    return (
        <AdminLayout>
            {isAuthenticated ? children : <Navigate to="/" />}
        </AdminLayout>
    );
};

function App(props) {

    const dispatch = useDispatch();

    console.log(window.location.pathname)

    useEffect(() => {
        dispatch(InitPermissions())
    }, [window.location.pathname])
    
    return (
        <div>
            <AppNotification />
            <Routes>
                <Route path="/" element={<Auth.Login />}/>
                <Route path="/dashboard" element={<PrivateRoute><Dashboard.Dashboard /></PrivateRoute>}/>

                {/* Returns */}
                <Route exact path="/dispatch" element={<PrivateRoute><Dispatch.Dispatch /></PrivateRoute>}/>
                <Route path="/dispatch/:id" element={<PrivateRoute><Dispatch.DispatchDetails /></PrivateRoute>}/>
                <Route path="/dispatch/vdn/:id" element={<PrivateRoute><Dispatch.CompletedVDNDetails /></PrivateRoute>}/>
                <Route path="/dispatch/create/summary/:id" element={<PrivateRoute><Dispatch.DispatchSummary /></PrivateRoute>}/>
                <Route path="/dispatch/update/vdn/summary/:id" element={<PrivateRoute><Dispatch.DispatchUpdateVDNSummary /></PrivateRoute>}/>
                <Route path="/dispatch/item/search" element={<PrivateRoute><ItemSearch.Search /></PrivateRoute>}/>

                {/* Returns */}
                <Route exact path="/returns" element={<PrivateRoute><Returns.Returns /></PrivateRoute>}/>
                <Route path="/returns/:id" element={<PrivateRoute><Returns.ReturnDetails /></PrivateRoute>}/>
                <Route path="/returns/vdn/:id" element={<PrivateRoute><Returns.CompletedRRNDetails /></PrivateRoute>}/>
                <Route path="/returns/create/summary/:id" element={<PrivateRoute><Returns.ReturnSummary /></PrivateRoute>}/>
            </Routes>
        </div>
    );
}

export default App;
