
import { AppRequest } from '../../../Services/AppRequest';
import { API } from '../Constant';
// import { login } from '../Reducer/Auth';

export const doLogin = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        AppRequest(API.Login + data)
        .then(res => {
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}