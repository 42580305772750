import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
// import SearchIcon from '@mui/icons-material/Search';
import Avatar from '@mui/material/Avatar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import { Menu, MenuItem } from '@mui/material';

export default function PrimarySearchAppBar({children, goBack = "", goBackIcon = "", title = "", date = "", hideAvatar = false, }) {

    const navigate = useNavigate();
    const [anchorEl] = React.useState(null);
    const [openProfileMenu, setOpenProfileMenu] = React.useState(false);


    return (
        <div>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar style={{height: 70}} position="fixed">
                    <Toolbar style={{marginTop: 6}}>
                        {!goBack ? 
                            <IconButton
                                size="large"
                                // edge="start"
                                color="inherit"
                                aria-label="open drawer"
                                sx={{ mr: 2 }}
                            >
                                <MenuIcon />
                            </IconButton>
                        : 
                            <IconButton
                                size="large"
                                // edge="start"
                                color="inherit"
                                onClick={() => navigate(goBack)}
                                aria-label="open drawer"
                                sx={{ mr: 2 }}
                            >
                                {goBackIcon ? goBackIcon : <ArrowBackIosIcon />}
                            </IconButton> 
                        }
                        
                        {title} 
                        
                        <Box sx={{ flexGrow: 1 }} />
                        {!hideAvatar ?
                            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                                <IconButton
                                    size="medium"
                                    aria-label="show more"
                                    color="inherit"
                                    onClick={() => setOpenProfileMenu(true)}
                                >
                                    <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                </IconButton>
                            </Box>
                        : null }
                    </Toolbar>
                </AppBar>
                <div style={{marginTop: 70}}>{children}</div>
            </Box>
        </div>
    );
}
