import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
// import { useNavigate  } from 'react-router-dom';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import AppModal from '../../../Components/AppModal';
import { useDispatch } from 'react-redux';
import { updateItemQuantityMethod, updateItemQuantityMethodVDN } from '../Methods';


const { innerHeight: height } = window;

const useStyles = makeStyles({
    card: {
        background: '#fff', 
        // '&:hover': {
        //     background: 'rgb(25 118 210)',
        //     color: '#fff',
        // }
    },
});

export default function DispatchTwoList({ type = "", data = [], parentId = "", hideSideBox = false, disabledUpdateItemCount = false}) {

    const classes = useStyles();
    const dispatch = useDispatch();
    // const navigate = useNavigate();
    console.log(data, 'datadatadata')

    const [quantityValue, setQuantityValue] = useState(0);
    const [quantityUpdateModal, setQuantityUpdateModal] = useState({
        open: false,
        selected: ''
    });

    const updateItemQuantity = () => {
        dispatch(updateItemQuantityMethod({
            parentId,
            item: quantityUpdateModal.selected,
            quantity: quantityValue
        }));
    }


    return (
        <div style={{padding: '1px 0 10px 0', height: "100vh", overflow: 'scroll'}}>
            {data.map((value, index) => (
                <div key={index} onClick={() => { 
                    
                    if(!disabledUpdateItemCount) {

                        setQuantityUpdateModal(p => ({...p, open: true, selected: value})); 
                        setQuantityValue(value.qty || value?.cdo_qty)
                    }
                    
                    }} className={classes.card} style={{background: value.from === "search" ? "#fff5de" : "#fff" , padding: 15, marginBottom: 0.2, borderBottom: '1px solid #e1e1e1', borderRadius: 5}}>
                    <div style={{display: 'flex'}}>
                        <div>
                            <div style={{fontSize: 23}}><b>{value.cdo_qty}</b></div>
                        </div>
                        <div style={{marginLeft: 15, width: "100%"}}>
                            <div style={{color: '#b2b2b2'}}><b>{value.sku_code}</b></div>
                            <div style={{marginTop: -5}}>{value.item_name}</div>
                        </div>
                        <div style={{border: hideSideBox ? "unset" : "1px solid #dcdcdc", color: "#2ea7fa", borderRadius: 5, width: "20%", textAlign: 'center', paddingTop: 4, fontSize: 20}}><b>{value.qty}</b></div>
                    </div>
                </div>
            ))}
            <AppModal disablePadding open={quantityUpdateModal.open} actionButtons={
                <div>
                    <Button style={{color: 'gray'}} onClick={() => setQuantityUpdateModal(p => ({...p, open: false}))}>CANCEL</Button>
                    <Button onClick={() => {
                        setQuantityUpdateModal(p => ({...p, open: false})); 
                        updateItemQuantity()
                    }} autoFocus>DONE</Button>
                </div>
            }>
                <div style={{padding: "10px 10px 5px 20px", borderBottom: "1px solid #dbdbdb", display: "flex"}}>
                    <div style={{ marginTop: 10}}>
                        <div style={{marginBottom: -6, color: "#8a8a8a"}}><b>{quantityUpdateModal?.selected?.sku_code}</b></div>
                        <div>{quantityUpdateModal?.selected?.item_name}</div>
                    </div>
                </div>
                <div style={{fontSize: 25, marginTop: 20, marginBottom: 10, color: "#8a8a8a", textAlign: 'center'}}><b>Order: {quantityUpdateModal?.selected?.cdo_qty}</b></div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 20}}>
                    <div>
                        <RemoveIcon onClick={e => setQuantityValue(quantityValue > 0 ? quantityValue - 1 : 0)} style={{fontSize: 35, marginTop: 10, marginRight: 25, border: "1px solid #ebebeb", borderRadius: 5}} />
                    </div>
                    <div>
                        <input 
                            value={quantityValue} 
                            onChange={e => setQuantityValue(e.target.value >= 0 ? e.target.value : 0)} 
                            type="number" 
                            style={{padding: '5px', width: '90px', fontSize: 35, fontWeight: "bold", border: '1px solid #ebebeb', borderRadius: 4, textAlign: "center"}} 
                        />
                    </div>
                    <div>
                        <AddIcon onClick={e => setQuantityValue(quantityValue + 1)} style={{fontSize: 35, marginTop: 10, marginLeft: 25, border: "1px solid #ebebeb", borderRadius: 5}} />
                    </div>
                </div>
            </AppModal>
        </div>
    )
}