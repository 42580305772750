
import { AppRequest } from '../../../Services/AppRequest';
import { initNotification } from '../../ApplicationReducer';
import { API } from '../Constant';
import { getItems, setRRNDetails, getTripCategoryReducer, getItemDetails, updateItemQty, updateVDNNumber, setSelectedItems, getRRNItems } from '../Reducer';

export const getCRN = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return AppRequest(API.GET_CRN + data)
        .then(res => {
            dispatch(getItems(res.data));
            dispatch(initNotification({open: false, message: "", type: "loading"}));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const getRRN = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return AppRequest(API.GET_RRN + data)
        .then(res => {
            dispatch(getRRNItems(res.data));
            // dispatch(initNotification({open: false, message: "", type: "loading"}));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const getCNRDetails = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(initNotification({open: true, message: "Fetching...", type: "loading"}));
        return AppRequest(API.GET_CRN_DETAILS + "?crn_id=" + id)
        .then(res => {
            dispatch(initNotification({open: false, message: "", type: "loading"}));
            dispatch(getItemDetails({id, data: res.data}));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const getRRNDetails = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(initNotification({open: true, message: "Fetching...", type: "loading"}));
        return AppRequest(API.GET_RRN_DETAILS + "?rrn_id=" + id)
        .then(res => {
            dispatch(initNotification({open: false, message: "", type: "loading"}));
            dispatch(setRRNDetails({id, data: res.data}));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const getTripsCategory = (data = "") => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(initNotification({open: true, message: "Fetching...", type: "loading"}));
        return AppRequest(API.GET_TRIP_CATEGORY + data)
        .then(res => {
            dispatch(getTripCategoryReducer(res.data));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}

export const createCRN = (id, data = "") => (dispatch) => {
    return new Promise((resolve, reject) => {
        return AppRequest(API.CREATE_CRN, data, "POST")
        .then(res => {
            if(res.data.data) {
                dispatch(updateVDNNumber({id, vdn_number: res.data.data.document_number}));
                return resolve(res)
            }

            dispatch(initNotification({open: true, message: "Document not saved! Please contact admin.", type: "error"}));

        })
        .catch(error => {
            reject(error)
        })
    });
}

export const updateItemQuantityMethod = (data = "") => (dispatch) => {
    dispatch(updateItemQty(data))
}

export const mergeSelectedItem = (id, data = "") => (dispatch) => {
    
    dispatch(setSelectedItems({id, data}))
    
}