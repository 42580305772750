import { configureStore } from '@reduxjs/toolkit';
import AuthReducer from '../Modules/Auth/Reducer/Auth';
import DispatchReducer from '../Modules/Dispatch/Reducer';
import ReturnsReducer from '../Modules/Returns/Reducer';
import ItemSearchReducer from '../Modules/ItemSearch/Reducer';
import ApplicationReducer from '../Modules/ApplicationReducer';

export const store = configureStore({
  reducer: {
    auth: AuthReducer,
    dispatch: DispatchReducer,
    searchItems: ItemSearchReducer,
    application: ApplicationReducer,
    returns: ReturnsReducer
  },
})