
import { AppRequest } from '../../../Services/AppRequest';
import { initNotification } from '../../ApplicationReducer';
import { getItems, setSelectItem } from '../../Dispatch/Reducer';
import { API } from '../Constant';
import { searchItems } from '../Reducer';

export const getSearchItems = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch(initNotification({open: true, message: "Fetching...", type: "loading"}));
        return AppRequest(API.SEARCH_ITEMS)
        .then(res => {
            dispatch(searchItems(res.data));
            dispatch(initNotification({open: false, message: "", type: ""}));
            resolve(res)
        })
        .catch(error => {
            reject(error)
        })
    });
}


export const selectItem = (v) => (dispatch) => {
   dispatch(getItems(v));
}