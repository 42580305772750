import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PageContainer from '../../../Components/PageContainer';
import InventoryIcon from '@mui/icons-material/Inventory';
import AdminHeader from '../../../Templates/AdminHeader';
import AppHelmet from '../../../Components/AppHelmet';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useSelector } from 'react-redux';

const useStyles = makeStyles({
    card: {
        background: '#fff', 
        height: 150,
        '&:hover': {
            background: '#1976d2',
            color: '#fff'
        }
    },
});

export default function Login() {

    const navigate = useNavigate();
    const classes = useStyles();

    const [menus, setMenus] = useState([])

    const permissions = useSelector(state => state?.application?.permissions);

    const menusData = [
        {
            label: "Dispatch",
            url: '/dispatch',
            icon: <InventoryIcon style={{fontSize: 60}} />,
            is_view: !permissions?.dispatch?.privilege?.includes('D')
        },
        {
            label: "Returns",
            url: '/returns',
            icon: <ShoppingCartIcon style={{fontSize: 60}} />,
            is_view: !permissions?.returns?.privilege?.includes('D')
        }
    ]

    useEffect(() => {
        if(permissions) {
            setMenus(menusData)
        }
    }, [permissions])

    console.log(permissions, "permissions?.returns?.privilege?.includes('D')")

    return (
        <div>
            <AdminHeader />
            <AppHelmet style={`body {
                    background-color: #f0f8fe;
                }`}/>
            <PageContainer>
                <Grid container spacing={1}>
                    {menus.map((value, index) => {
                        console.log(value.is_view, 'dddddddddddd')
                        if(value.is_view)
                            return (
                                <Grid onClick={() => navigate(value.url)} key={index} item xs={6}>
                                    <div className={classes.card}>
                                        <div style={{textAlign: 'center', paddingTop: 30}}>
                                            <div>{value.icon}</div>
                                            <div><b>{value.label}</b></div>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        return null;
                    })}
                </Grid>
            </PageContainer>
        </div>
    )
}