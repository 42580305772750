import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    open: false,
    message: "",
    icon: "",
    vertical: "bottom",
    horizontal: "center",
    type: "",
    permissions: ""
}

export const slice = createSlice({
    name: 'application-reducer',
    initialState,
    reducers: {
        initNotification: (state, action) => {
            state = {
                ...state,
                ...action.payload
            }
            return state;
        },
        InitPermissions: (state, actions) => {
            
            let permissions = sessionStorage.getItem('auth_user');
            permissions = JSON.parse(permissions);
            permissions = permissions?.priv_class;
            permissions = {
                dispatch: permissions?.find(i => i.secured_object_id === 1),
                returns: permissions?.find(i => i.secured_object_id === 2)
            }
            permissions = {
                ...permissions,
                dispatch: {
                    ...permissions.dispatch,
                    privilege: permissions.dispatch?.privilege ? permissions.dispatch?.privilege.split('/') : ['D']
                },
                returns: {
                    ...permissions.returns,
                    privilege: permissions.returns?.privilege ? permissions.returns?.privilege.split('/') : ['D']
                }
            }
            state.permissions = permissions;
            return state;
        }
    }
})

export const { initNotification, InitPermissions } = slice.actions

export default slice.reducer