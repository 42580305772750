import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  data: [],
}

export const slice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchItems(state, action) {

      state.data = action.payload.map(i => ({...i, selected: false}))
      return state;
    },
    setSelectItemBKB(state, action) {
      state.data = state.data.map(i => i.id === action.payload ? {...i, selected: !i.selected} : i)
    },
    setSelectItem(state, action) {
      state.data = state.data.unshift({
        ...action.payload,
        qty: 0
      });

      return state;
    }
  },
})

// Action creators are generated for each case reducer function
export const { searchItems, setSelectItem } = slice.actions

export default slice.reducer