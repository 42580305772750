import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function AlertDialog({ open = false, title = "", id = "", children, actionButtons = "", disablePadding = false}) {

    return (
        <div>
            <Dialog
                fullWidth
                open={open}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                {title ? <DialogTitle id="alert-dialog-title"></DialogTitle> : null }
                {!disablePadding ? 
                    <DialogContent>
                        <DialogContentText id={"alert-dialog-description-" + id}>
                            {children}
                        </DialogContentText>
                    </DialogContent>
                : children }
                <DialogActions>
                   {actionButtons}
                </DialogActions>
            </Dialog>
        </div>
    );
}
