import React, { useState } from 'react';
import AppButton from '../../../Components/Forms/Button';
import TextBox from '../../../Components/Forms/TextBox';
import { trans } from '../../../Utility';
import Layouts from '../Layouts';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { doLogin } from '../Methods/Auth';
import { AppRequest } from '../../../Services/AppRequest';
import { InitPermissions } from '../../ApplicationReducer';


export default function Login() {

    const navigate = useNavigate();
    
    const dispatch = useDispatch();
    
    const [input, setInput] = useState({ username: '', password: '' });
    const [error, setError] = useState({ username: '', password: '' });
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    const handleSubmit = () => {
        
        let isError = false;
        setError({password: '', username: ''});
        if((! input.username) && (! input.password)) {
            return setError({ ...error, username: 'Username is required', password: 'Password is required' });
        }
        if((! input.username)) {
            isError = true;
            setError({ ...error, username: 'Username is required', password: ''});
        }
        if((! input.password)) {
            isError = true;
            setError({ ...error, password: 'Password is required', username: ''});
        }

        if(isError) return false;
        setIsLoading(true)
        dispatch(doLogin(`?username=${input.username}&password=${input.password}`))
        .then(res => {
            if(res.data.status) {
                // setTimeout(() => , 5000)
                sessionStorage.setItem('auth_user', JSON.stringify(res.data.data));
                dispatch(InitPermissions())
                setTimeout(() => navigate('/dashboard'), 100);
                return;
            }
            setIsLoading(false)
            return setError({ ...error, username: res.data.message });
        })
        .catch(err => {})
    }

    return (
        <Layouts title={"auth.login.title"} subTitle={"auth.login.sub-title"}>
            <div>
                <div>
                    <TextBox error={error.username !== ""} helperText={error.username} name="username" fullWidth size="medium" label="Username" onChange={handleChange} />
                </div>
                <div style={{marginTop: 30}}>
                    <TextBox type="password" error={error.password !== ""} helperText={error.password} name="password" fullWidth size="medium" onChange={handleChange} label="Password" />
                </div>
                <div style={{marginTop: 20}}>
                    <AppButton onClick={handleSubmit} fullWidth size="large"  label={isLoading? 'Please wait...' : trans("auth.button.sign-in")} />
                </div>
            </div>
        </Layouts>
    )
}