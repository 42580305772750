import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { MobileDatePicker, DesktopDatePicker, DateTimePicker, TimePicker,LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@date-io/date-fns';

export default function AppDatePicker({ value = "", close = () => {}, open = false, onChange = () => {} }) {
  const [valueData, setValueData] = React.useState(value || new Date());

  const handleChange = (newValue) => {
    setValueData(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack spacing={3}>
            <MobileDatePicker
                hidePicker
                open={open}
                onClose={() => close()}
                label="Date mobile"
                inputFormat="MM/dd/yyyy"
                value={valueData}
                onChange={handleChange}
                onAccept={(e) => onChange(e)}
                renderInput={(params) => <TextField {...params} />}
            />
        </Stack>
    </LocalizationProvider>
  );
}
