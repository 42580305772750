import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function AppSelect({ label = "", size = "", options = [], value = "", handleChange = () => {}, placeholder = "" }) {

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl size={size} fullWidth>
                <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={value}
                    label={label}
                    onChange={handleChange}
                >
                    <MenuItem value={""}>- {placeholder} -</MenuItem>
                    {options.map((value, index) => <MenuItem key={index} value={value.id}>{value.label}</MenuItem>)}
                    
                </Select>
            </FormControl>
        </Box>
    );
}