/* eslint-disable react-hooks/rules-of-hooks */
import { FormattedMessage, useIntl } from 'react-intl'

export function lang(name) {

    return <FormattedMessage id={name} />
}

export function trans (transKey, addModule = true, options = {}) {

    if(! transKey) return "";

    try {
        const { formatMessage } = useIntl();
        if (addModule) return formatMessage({id: `${transKey}`}, options);
    
        return formatMessage({id: `${transKey}`}, options);
    } catch (error) {
        return transKey;
    }
};